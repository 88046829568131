<template>
    <div>
        <footer class="footer-three p_relative">
            <div class="auto-container">
                <div class="footer-widget-section">
                    <div class="row clearfix">
                        <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget about-widget">
                                <div class="footer-logo p_relative d_block mb_35">
                                    <a href="#" aria-label="Home">
                                        <!-- <img
                                            class="image-iterates" loading="lazy" data-src="/assets/images/iterates.png"
                                            alt="IT services and consulting in Brussels"> -->
                                        <img class="image-iterates" loading="lazy" :src="FooterImage" alt="iterates logo"
                                            aria-label="iterates">
                                    </a>
                                </div>
                                <!-- <div id="footertextdn" class="text footertext d-none d-sm-block">
                                    <p >{{ $t('globalTrans.footer_text') }}</p>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget links-widget ml_80">
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget links-widget">
                                <div class="widget-title">
                                    <h4>iterates</h4>
                                </div>
                                <div class="widget-content put_it_on_two_columns">
                                    <ul class=" links-list clearfix ">
                                       
                                        <li> <router-link to="/">{{ $t('globalTrans.home') }}</router-link></li>
                                        <li><router-link to="/consulting-it-brussels-belgium">{{ $t('globalTrans.consulting_menu') }}</router-link>
                                        </li>
                                        <li><router-link to="/join-us">{{ $t('globalTrans.joinus') }}</router-link>
                                        </li>
                                        <li><router-link to="/contact">{{ $t('globalTrans.contact') }}</router-link></li>
                                        <li><a href="https://www.iterates.be/blog/" target="_blank">{{
                                            $t('globalTrans.blog') }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget contact-widget">
                                <div class="widget-title">
                                    <h4>Contact</h4>
                                </div>
                                <div class="widget-content">
                                    <ul class="info-list clearfix">
                                        <li><a href="https://goo.gl/maps/Azu6GCFFhpDUCJ6J9" target="_blank">{{
                                            $t('globalTrans.address') }}</a></li>
                                        <li>
                                            <a href="mailto:consulting@iterates.be">{{ $t('globalTrans.company_email') }}</a>
                                        </li>
                                        <ul style="display:flex ; flex-direction:row; gap:1em">
                                            <li>
                                                <a target="_blank" href="https://www.instagram.com/iterates.be/" class=""><i
                                                        class="h2 fab fa-instagram-square"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href="https://www.linkedin.com/company/iterates-it-development-services-and-consulting-brussels-belgium/ " class="" data-v-e1216298="">
                                                    <i class="h2 fa-brands fa-linkedin" data-v-e1216298=""></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="auto-container">
                    <div class="bottom-inner clearfix">
                        <div class="copyright pull-left">
                            <p> {{ $t('globalTrans.iterates') }} &copy; {{ $t('globalTrans.year') }}</p>
                        </div>
                        <ul class="footer-nav clearfix pull-right">
                            <li><router-link class="h6 small" :to="{name: 'policy'}">Privay Policy</router-link></li>
                            <li><a class="h6 small" href="https://meet.jit.si/iterates-online-meeting" target="_blank">{{
                                $t('globalTrans.online_meeting') }}</a>
                            </li>
                            <li><a class="h6 small" href="https://www.instagram.com/sskprod.be/?hl=fr" target="_blank">{{
                                $t('globalTrans.footer') }}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    name: 'FooterSection',
    data() {
        return {
            FooterImage: '/assets/images/iterates.png'
        }
    },
    created() {
},
}
</script>
<style scoped>

.put_it_on_two_columns{
    @media screen and (max-width:549px) {
       columns: 2;
        
    }
}
</style>
