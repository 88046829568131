<template>
    <div>
        <div class="boxed_wrapper">
            <Header />
            <router-view v-slot="{ Component }">
                <transition name="fade">
                    <component :is="Component" />
                </transition>
            </router-view>
            <Footer />
        </div>
    </div>
</template>
<script>
import Header from '@/components/HeaderSection'
import Footer from '@/components/FooterSection'
export default {
    name: 'MainView',
    components: {
        Header,
        Footer
    }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>