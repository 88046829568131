import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

const app = createApp({ ...App,
    mounted() {
        // merged options exposed on this.$options
        console.log("test")
      }
});

// Use Vuex, Vue Router, and i18n as middleware
app.use(store);
app.use(router);
app.use(i18n);

// Mount the Vue app
app.mount('#app');
