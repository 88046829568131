/* eslint-disable */
import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import i18n from '../i18n'; // Import your i18n instance
import VueMeta from 'vue-meta'

// Vue.use(VueMeta)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: function () {
      return import( '../views/pages/HomeSection.vue')
    }
  },
  {
    path: '/consulting-it-brussels-belgium',
    name: '/consulting-it-brussels-belgium',
    component: function () {
      return import( '../views/pages/ConsultingSection.vue')
    }
  },
  {
    path: '/join-us',
    name: 'join-us',
    component: function () {
      return import( '../views/pages/JoinUsSection.vue')
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: function () {
      return import( '../views/pages/ContactSection.vue')
    }
  },
  {
    path: '/privacy-policy',
    name: 'policy',
    component: function () {
      return import( '../views/pages/PrivacyPolicy')
    }
  },
  
  //   path: '/software-development-belgium',
  //   name: 'software_development_belgium',
  //   component: function () {
    //     return import( '../views/pages/services/SoftwareDevelopmentBelgium.vue')
    //   }
    // },
    // {
      //   path: '/automation-of-your-processes',
  //   name: 'automation_of_your_processes',
  //   component: function () {
  //     return import( '../views/pages/services/AutomationOfYourProcesses.vue')
  //   }
  // },
  // {
  //   path: '/artificial-intelligence',
  //   name: 'artificial_intelligence',
  //   component: function () {
  //     return import( '../views/pages/services/ArtificialIntelligence.vue')
  //   }
  // },
  // {
  //   path: '/application-creation',
  //   name: 'application_creation',
  //   component: function () {
  //     return import( '../views/pages/services/ApplicationCreation.vue')
  //   }
  // },
  // {
  //   path: '/projectDetails/:code',
  //   name: 'our_projects',
  //   component: function () {
  //     return import( '../views/pages/ProjectDetails.vue')
  //   },
  //   props: true 
  // }
]



// Add localized routes for 'fr' locale
const localizedRoutes = routes.map((route) => {
  return {
    ...route,
    name:`fr_${route.name}`,
    path: `/fr${route.path}`, // Add '/fr' prefix
  };
});

// Combine both sets of routes
const allRoutes = [...routes, ...localizedRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: allRoutes,
})
function redirectToLocalizedRoute(to, locale) {
  const routePath = to.path;
  if (!routePath.startsWith(`/${locale}/`)) {
    return `/${locale}${routePath}`;
  }
  return routePath;
}

router.beforeEach((to, from, next) => {
  const currentLocale = i18n.global.locale;
  document.body.classList.remove('mobile-menu-visible');
  window.scrollTo(0, 0)
  if (currentLocale === 'fr' && !to.path.startsWith('/fr/')) {
    const redirectedPath = redirectToLocalizedRoute(to, 'fr');
    next(redirectedPath);
  } else if (currentLocale === 'en' && to.path.startsWith('/fr/')) {
    // Remove the '/fr/' prefix for English locale
    const newPath = to.path.replace(/^\/fr\//, '/');
    next(newPath);
  } else {
    next();
  }
});

export default router
