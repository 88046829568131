<template>
    <div>
        <header class="main-header header-style-two header-style-12 fixed-header">
            <!-- header-lower -->
            <div class="header-lower " id="headerWrp">
                <div class="outer-container">
                    <div id="header-box" class="outer-box">
                        <div class="logo-box">
                            <div class="logo">
                                <!-- <a href="#"><img class="image-iterates" loading="lazy"
                                        data-src="@/assets/images/iterates.png" alt="development services in Brussels"
                                        aria-label="iterates">
                                    </a> -->
                                <a href="/">
                                    <img class="image-iterates" loading="lazy" :src="profileImage"
                                        alt="development services in Brussels" aria-label="iterates">
                                </a>
                            </div>
                        </div>
                        <div class="menu-area clearfix">
                            <!--Mobile Navigation Toggler-->
                            <div class="mobile-nav-toggler">
                                <i class="icon-bar"></i>
                                <i class="icon-bar"></i>
                                <i class="icon-bar"></i>
                            </div>
                            <nav id="sticky-nav" class="main-menu navbar  navbar-expand-md navbar-light">
                                <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                                    <ul class="navigation navbar-nav clearfix home-menu">
                                        <li class=" dropdown">
                                            <router-link to="/"
                                                :class="{ 'router-link-active router-link-exact-active': isHome }">{{
                                                    $t('globalTrans.home') }}</router-link>
                                        </li>
                                        <li ><router-link to="/consulting-it-brussels-belgium"
                                                :class="{ 'router-link-active router-link-exact-active': isConsulting }">{{
                                                    $t('globalTrans.consulting_menu') }}</router-link>
                                        </li>
                                        <li><router-link to="/join-us"
                                                :class="{ 'router-link-active router-link-exact-active': isJoinUS }">{{
                                                    $t('globalTrans.joinus') }}</router-link>
                                        </li>
                                        <li><router-link to="/contact"
                                                :class="{ 'router-link-active router-link-exact-active': isContact }">{{
                                                    $t('globalTrans.contact') }}</router-link>
                                        </li>
                                        <li class="dropdown"><a href="https://www.iterates.be/blog/" target="_blank">{{
                                            $t('globalTrans.blog') }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <nav id="nav-right" class="main-menu nav-right">
                            <ul class="navigation nav-language">
                                <li class="current-fr" v-if="currentLocale == 'en'">
                                    <a href="#" @click="changeLang('fr')">FR </a>
                                </li>
                                <li class="current-en" v-if="currentLocale == 'fr'">
                                    <a href="#" @click="changeLang('en')">EN</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!-- Mobile Menu  -->
            <div class="mobile-menu">
                <div class="menu-backdrop"></div>
                <div class="close-btn"><i class="fas fa-times"></i></div>

                <nav class="menu-box">
                    <div class="nav-logo">
                    </div>
                    <div class="menu-outer">

                        <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                            <ul class="navigation clearfix home-menu">
                                <li class=" dropdown">
                                  <router-link to="/"
                                        :class="{ 'router-link-active router-link-exact-active': isHome }">{{
                                            $t('globalTrans.home') }}</router-link>
                                </li>
                                <!-- <li class="dropdown">
                                    <router-link to="/about"
                                        :class="{ 'router-link-active router-link-exact-active': isAbout }">{{
                                            $t('globalTrans.about_us') }}</router-link>
                                </li> -->
                                <li class="dropdown">
                                    <router-link to="/consulting"
                                        :class="{ 'router-link-active router-link-exact-active': isConsulting }">{{
                                            $t('globalTrans.consulting') }}</router-link>
                                </li>
                                <li><router-link to="/joinus"
                                                :class="{ 'router-link-active router-link-exact-active': isJoinUS }">{{
                                                    $t('globalTrans.joinus') }}</router-link>
                                        </li>
                                <li><router-link to="/contact"
                                        :class="{ 'router-link-active router-link-exact-active': isContact }">{{
                                            $t('globalTrans.contact') }}
                                    </router-link></li>
                                <li class="dropdown"><a href="https://www.iterates.be/blog/" target="_blank">{{
                                    $t('globalTrans.blog') }}</a>
                                </li>
                            </ul>
                            <ul class="navigation nav-language">
                                <li class="current-fr" v-if="currentLocale == 'en'">
                                    <a href="#" @click="changeLang('fr')">FR </a>
                                </li>
                                <li class="current-en" v-if="currentLocale == 'fr'">
                                    <a href="#" @click="changeLang('en')">EN</a>
                                </li>
                            </ul>
                        </div>


                    </div>
                </nav>
            </div><!-- End Mobile Menu -->

            <!--sticky Header-->


        </header>
    </div>
</template>
<script>
export default {
    name: 'HeaderSection',
    data() {
        return {
            profileImage: '/assets/images/iterates.png',
            currentLocale: 'en'

        }
    },
    created() {

        const pathSegments = window.location.pathname.split('/');
        const languageFromURL = pathSegments[1]; // Assuming the language is the first path segment

        // Check if the language from the URL is a valid locale
        const validLocales = ['en', 'fr']; // Add more valid locales as needed

        if (validLocales.includes(languageFromURL)) {
            this.currentLocale = languageFromURL;
        } else {
            // Default to 'en' if the language code is not valid or missing
            this.currentLocale = 'en';
        }

        // Set the locale in your i18n instance
        this.$i18n.locale = this.currentLocale;

        const script = document.createElement('script');

        // Set the script source
        script.src = '/assets/js/mobileMenuScript.js'; // Replace with the actual URL

        // Attach the script to the DOM
        document.head.appendChild(script);
    },
    computed: {
        isHome() {
            return this.$route.name === "Home" || this.$route.name === "fr_Home";
        },
        isAbout() {
            return this.$route.name === "about" || this.$route.name === "fr_about";
        },
        isConsulting() {
            return this.$route.name === 'consulting-it-brussels-belgium' ||   this.$route.name === 'fr_consulting-it-brussels-belgium' ;
        },
        isContact() {
            return this.$route.name === "contact" || this.$route.name === "fr_contact";
        },
        isJoinUS() {
            return this.$route.name === "joinus" || this.$route.name === "fr_joinus";
        }

    },
    methods: {
        changeLang(lang) {
            this.currentLocale = lang;
            this.$i18n.locale = lang;
        }
    }
}
</script>
<style scoped>
.menuParent {
    position: relative !important;
}

.menuParent:hover .dropdown-menu {
    display: block
}

.dropdown-menu {
    top: 80%;
}


.dropdown-item:hover,
.dropdown-item:focus {
    background-color: transparent;
    text-decoration: none;
    color: #fb4754 !important
}
</style>
