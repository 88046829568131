<template>
  <MainView />
</template>
<script>
import MainView from './views/MainView.vue'
export default {
  name: 'app',
  components: {
    MainView
  },
  mounted() {
    console.log("mountme")

  },
  metaInfo: {
     
      title: 'iterates consulting',
      // all titles will be injected into this template
      titleTemplate: '%s | brussels consulting society'
    }

}
</script>

