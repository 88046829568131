import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import fr from './locales/fr.json'
import { get } from 'lodash';

function loadLocaleMessages() {
  const locales = [{ en: en }, { fr: fr }]
  const messages = {}
  locales.forEach(lang => {
    const key = Object.keys(lang)
    messages[key] = lang[key]
  })
  return messages
}

let userLanguage = 'en';
const browserLanguage = navigator.language || navigator.userLanguage;
if (browserLanguage && browserLanguage.startsWith('fr')) {
  userLanguage = 'fr';
}

export default createI18n({
  locale: userLanguage,
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  messageResolver: (obj, path) => get(obj, path, '')?.replaceAll(/@/g, "{'@'}") || null
})
